import React from 'react'
import { useState, useEffect } from 'react'
import { Modal, Tabs, Button, Empty } from 'antd'
import { orderReportOne } from '@/service/ordersService'
import ReactMarkdown from 'react-markdown'
import { Transformer } from 'markmap-lib'
import { loadCSS, loadJS, Markmap } from 'markmap-view'
import styled from '@emotion/styled'
import mermaid from 'mermaid'

function ViewReport(props) {
    const { orderId, visible, setViewReportInfo } = props

    useEffect(() => {
        if (visible && orderId) {
            getInfo()
        }
    }, [visible, orderId])

    const [markdownInfo, setMarkdownInfo] = useState(null)

    const getInfo = () => {
        orderReportOne(orderId).then((res) => {
            const { retData } = res
            if (retData) {
                const { report, reportInfo } = retData
                setMarkdownInfo(report)
                getMermaidInfo(reportInfo.orderInfo)
            }
        })
    }

    const [mermaidInfo, setMermaidInfo] = useState(null)
    const getMermaidInfo = (orderInfo) => {
        const data = ` 
        sequenceDiagram
        autonumber
        participant 创建方式 as ${orderInfo.orderSource}
        participant 创建人 as ${orderInfo.createUserName}(创建人)
        participant 派单人 as ${orderInfo.dispatchUserName}(派单人)
        participant 维修团队 as ${orderInfo.teamName} (维修团队)
        participant 维修技师 as ${orderInfo.majorUserName} (主修人)
        participant 审核人 as ${orderInfo.reviewerName} (审核人)
        participant 结算人 as ${orderInfo.settlementPartyName}(结算方式) 
        participant 结算人 as ${orderInfo.settlementPartyName}(结算方式) 
        participant 工单完成 as 工单完成 
    
        note right of 创建方式: 创建维修工单
        创建方式 ->> 创建人: 创建工单 ${orderInfo.createTime}
        创建人 ->> 派单人: 提交工单
    
        派单人 ->> 维修团队: 分配工单 ${orderInfo.dispatchTime}
    
        维修团队 ->> 维修技师: 分派至${orderInfo.teamUsers} (${orderInfo.dispatchTime})
        维修技师 ->> 维修技师: 开始维修 (${orderInfo.teamStartMaintenanceTime})
        维修技师 ->> 维修技师: 完成维修 (${orderInfo.teamMaintenanceCompleteTime})
        维修技师 -> 审核人: 提交审核 
    
        审核人 ->> 审核人: 审核 (${orderInfo.reviewerTime})
        审核人 ->> 结算人: 结算 (${orderInfo.settlementTime})
        结算人 ->> 工单完成: 完成时间 (${orderInfo.orderCompletedTime})`

        setMermaidInfo(data)
    }

    const setMarkdownView = () => {
        const transformer = new Transformer()
        //this.initValue就是我们的markdom数据，此方法就是转换markdom数据，可以打印root查看数据结构
        const { root, features } = transformer.transform(markdownInfo)
        const { styles, scripts } = transformer.getUsedAssets(features)
        // const { markmap } = window
        if (styles) loadCSS(styles)
        // if (scripts) loadJS(scripts, { getMarkmap: () => markmap })
        const svgEl = document.querySelector('#markMap')
        const mm = Markmap.create(svgEl, undefined, root)
    }

    const getScript = () => {
        let script1 = document.createElement('script')
        let script2 = document.createElement('script')
        script1.type = 'text/javascript'
        script2.type = 'text/javascript'
        script1.src = 'https://cdn.jsdelivr.net/npm/d3@6'
        script2.src = 'https://cdn.jsdelivr.net/npm/markmap-view'
        document.getElementById('root').appendChild(script1)
        document.getElementById('root').appendChild(script2)
    }
    const handleCancel = () => {
        setViewReportInfo({
            visible: false,
            orderId: '',
        })
    }
    const [activeKey, setactiveKey] = useState('item-1')
    const onChange = (activeKey) => {
        setactiveKey(activeKey)
        if (activeKey === 'item-2' && markdownInfo) {
            setTimeout(() => {
                setMarkdownView()
            }, 200)
        }
        if (activeKey === 'item-3' && markdownInfo) {
            setTimeout(() => {
                setMermaidView()
            }, 10)
        }
    }

    const setMermaidView = () => {
        mermaid.initialize({
            startOnLoad: true,
            theme: 'default',
        })
        mermaid.init(undefined, document.querySelectorAll('.mermaid'))
    }
    return (
        <Modal
            visible={visible}
            title="报告查看"
            footer={[
                <Button onClick={handleCancel} key="back">
                    取消
                </Button>,
            ]}
            onCancel={handleCancel}
            width={'80vw'}
            destroyInactiveTabPane={true}
        >
            <Tabs onChange={onChange}>
                <Tabs.TabPane tab="报告详情" key="item-1">
                    <ReactMarkdownBox>{markdownInfo ? <ReactMarkdown>{markdownInfo}</ReactMarkdown> : <Empty />}</ReactMarkdownBox>
                </Tabs.TabPane>
                <Tabs.TabPane tab="思维导图" key="item-2">
                    {activeKey === 'item-2' && markdownInfo ? <svg id="markMap" style={{ width: '80vw', height: '80vh' }}></svg> : <Empty />}
                </Tabs.TabPane>
                <Tabs.TabPane tab="流程分析" key="item-3">
                    {activeKey === 'item-3' && markdownInfo ? <div className="mermaid" style={{ width: '80vw', height: '40vh' }}>{mermaidInfo}</div> : <Empty />}
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    )
}

export default ViewReport

const ReactMarkdownBox = styled.div`
    p {
        color: #4165e3;
        font-size: 14px;
        margin-top: 5px;
    }

    ul > li > strong {
        color: #1d1e21;
        // font-weight: 400;
    }

    ul > li {
        color: #9b9ea5;
        font-size: 12px;
    }
`
